import * as React from "react"

const PrivacyPolicyContent = () => {
    return (
		<>
      <div className="main-text-content ptb-100">
        <div className="container">
          <h3>1 Inledning</h3>

          <p>Amal Express Ekonomisk Förening, 769608-9247, (Föreningen) är en ekonomisk förening med säte i Göteborg som bedriver verksamhet med penningöverföring i Sverige i huvudsak för kunder som har sitt ursprung i Somalia, men även – i mindre omfattning – för kunder med ursprung i andra länder vid Afrikas Horn. Föreningen har hos Finansinspektionen (FI) genom beslut 2011-01-18 beviljats undantag från tillståndsplikt (registrerad betaltjänstleverantör) att tillhandahålla betaltjänster som anges i 1 kap. 2 § punkt 6 lagen (2010:751, ändrad bl.a. 2018:175) lagen om betaltjänster (penning-överföring).  Föreningen har också anmält ett antal s.k. betaltjänstombud till FI som registrerats.

Föreningen är personuppgiftsansvarig och har antagit denna integritetspolicy för att uppfylla de krav som uppställs i EU:s förordning 2016/679, General  Data Protection  Regulation (GDPR), (i Sverige även benämnd Dataskyddsförordningen) och även vissa andra närliggande författningar inom dataskyddsområdet som ska tillämpas i relevant utsträckning hos Föreningen. För samordning och säkerställande av att de legala kraven på dataskydd uppfylls i verksamheten kan Föreningen ingå avtal ingås med s. k. personuppgiftsbiträde utifrån behov.

Dataskyddsförordningen är direktbindande och gäller som lag inom alla EU-länder. Dataskyddsförordningen ställer höga krav på integritetsskydd för personer som är föremål för behandling av personuppgifter och ökade krav på företag och organisationer som behandlar personuppgifter. Det finns också ett anslutande EU direktiv 2016/680 som behandlar brotts-bekämpning m.m.

Vidare har antagits en svensk dataskyddslag benämnd Lag med kompletterande bestämmelser till EU:s dataskyddsförordning (SFS 2018:218) som trätt i kraft samtidigt som dataskyddsförordningen. En svensk förordning (SFS 2018:219) med kompletterande bestämmelser till EU:s dataskydds-förordning ger ytterligare bestämmelser samt specifikt mandat till Integritetsskyddsmyndigheten att meddela kompletterande föreskrifter om personuppgifter som rör artikel 10 i EU:s dataskyddsförordning. Ändringar kopplade till dataskyddsregelverket har också skett i lagen (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism. Därtill har ytterligare svenska regelverk för olika verksamhetsområden antagits. Det nya regelverket ersätter personuppgiftslagen (PUL).

I Sverige är Integritetsskyddsmyndigheten (IMY) tillsynsmyndighet. I tillsynsrollen ingår att publicera vägledning och kompletterande regelverk inom dataskyddsområdet (Integritetsskyddsmyndighetens författningssamling (DIFS).</p>

          <h3>2 Beskrivning av verksamheten och grundläggande principer för personuppgiftsbehandling </h3>
          <p>2.1 Beskrivning av verksamheten

Föreningens verksamhet består i att för kunders räkning genom avtal tillhandahålla betaltjänsten penningöverföring från avsändare i Sverige till mottagare i främst Somalia, men, i begränsad utsträckning, även till mottagare i andra länder vid Afrikas Horn. I huvudsak är avsändande kunder i Sverige anhöriga till mottagare i Somalia respektive till mottagare i andra länder vid Afrikas Horn. Föreningen har i sin verksamhet krav på att bl.a. på ett adekvat sätt tillämpa reglerna i betaltjänst-lagstiftningen och penningtvättslagstiftningen samt i övrigt uppfylla legala krav enligt Data-skyddsförordningen/GDPR  samt de regler som utfärdas av  tillsynsmyndigheterna IMY och FI m.fl.

Föreningen har som ett led i verksamheten bankavtal och samarbetsavtal med välrenommerade företaget Al Amal Express Exchange (Al Amal)  i Dubai.

2.2 Grundläggande principer för personuppgiftsbehandling

Föreningen ska utifrån relevans och verksamhetens art tillämpa följande grundläggande principer.

Rättslig grund m.m. Föreningens behandling av personuppgifter ska utföras i enlighet med en giltig rättslig grund som kan vara bl.a. insamling och bedömning av uppgifter för beredning, etablering och fullgörande av avtal som ingåtts med kunder, fullgörande av en rättslig förpliktelse, hantering av en uppgift av berättigat intresse eller som bygger på registrerads samtycke. Ett lämnat samtycke kan återkallas vilket kan få konsekvensen att behandling inte kan fortsättas såvida inte annan rättslig grund för behandling kan användas.
Föreningen ska utifrån relevans i Föreningens verksamhet kommunicera med de registrerade om ändamålet med den behandling som utförs som tydligt ska framgå, bestämmelser om delning av personuppgifter och tid för bevarande av personuppgifter samt meddelande om aktuella kontaktuppgifter till Företaget.
Personuppgifter som behandlas ska vara adekvata, relevanta och korrekta. Uppdatering av personuppgifter ska ske löpande utifrån behov. Hantering av personuppgifter och lagstadgade uppgifter i övrigt utifrån den lagstiftning om åtgärder mot penningtvätt och lagstiftning om betaltjänster som ska tillämpas i föreningens verksamhet styr såväl omfattning, typer av behandling, som tid för bevarande av uppgifter enligt penningtvätts-lagstiftningen.</p>

          <h3>3 Personuppgifter och användning av personuppgifter utifrån rättslig grund m.m.</h3>
          <p>3.1 Personuppgifter

Personuppgifter är alla uppgifter som avser en identifierad eller identifierbar fysisk person och som direkt eller indirekt kan identifiera en person. Identifiering kan även ske med användning av biometriska uppgifter.

Personuppgifter som Föreningen samlar in bl.a. för genomförande av betaltjänster är i huvudsak obligatoriska utifrån att Föreningens verksamhet omfattas av bl.a. bindande regler i penning-tvättslagstiftningen, betaltjänstlagstiftningen och andra relevanta författningar och som innefattar krav på inhämtande av känsliga personuppgifter i kundkännedomsprocessen och uppföljning av kunder såsom kontroll om kund eller s.k. verklig huvudman är person i politiskt utsatt ställning eller nära anhörig eller känd medarbetare till sådan person eller i samband med lagstadgad rapportering till bl.a. Polismyndigheten.

För alla kunder inhämtas grundläggande uppgifter om namn, personnummer, organisationsnummer, postadress, syfte och art med affärsförbindelsen med Föreningen och uppgifter i samband med löpande uppföljning för kunder som ingått affärsförbindelse (löpande avtal med Föreningen). Vidare inhämtas ekonomisk information och uppgifter om mottagare av penningöverföring samt görs kontroller mot EU:s sanktionslistor enligt de bindande legala krav som gäller. Den hantering och överföring av personuppgiftsinformation som behövs i samband med den hos FI registrerade betaltjänstverksamheten utgår från den registrerades samtycke och följer de krav som uppställts i FI:s beslut om registrering av Föreningens betaltjänstverksamhet.

3.2 Rättslig grund för behandling av personuppgifter

Ett grundläggande krav är att all behandling ska vara laglig vilket innebär att åtminstone någon av de rättsliga grunder som stadgas i dataskyddsförordningen ska uppfyllas. Därtill gäller att personuppgifter ska behandlas på ett lagligt, korrekt och öppet sätt visavi den registrerade. Som nämnts ovan gäller särskilda restriktioner med sekretess för verksamhet som omfattas av bl.a. penningtvättslagstiftningen. Vidare ska personuppgifter samlas in för särskilda, uttryckligt angivna och berättigade ändamål och får således inte senare behandlas på andra sätt som inte är kommunicerat och avstämt med den registrerade.

Behandling av personuppgifter måste således bygga på någon rättslig grund och/eller i vissa fall  samtycke såsom följande:

Den registrerade har lämnat samtycke till att personuppgifter behandlas för specifikt/specifika ändamål. Samtycke ska vara frivilligt, specifikt och informerat med innebörd att den registrerade godkänner behandlingen. Vissa särkrav gäller vid behandling som omfattas av penningtvättslagstiftningen (se nedan).
Behandlingen är nödvändig för att fullgöra ett avtal avseende t.ex. betaltjänster där den registrerade är part eller ska bli part.
Behandlingen är nödvändig för att fullgöra en rättslig förpliktelse eller annan åtgärd som bygger på legal grund. De som omfattas av lagstiftningen om åtgärder mot penningtvätt och finansiering av terrorism och vissa anknytande regelverk har skyldighet att tillämpa penningtvättsregelverket vilket innebär ändamål med rättsligt bindande krav på kundkännedom, uppföljning, granskning och kontroll av transaktioner, utredning om misstänkt penningtvätt m.m. (rättslig förpliktelse.)
Rättslig förpliktelse gäller även för hantering av bokföring, rapportering till skattemyndigheter och tillsynsmyndigheter (Finansinspektionen och Integritetsskyddsmyndigheten i fråga om Företagets verksamhet) m.m.
Behandlingen är nödvändig för att skydda intressen som är av grundläggande betydelse för den registrerade eller annan fysisk person.
Behandlingen är nödvändig för ändamål som berör den personuppgiftsansvarige eller tredje mans intressen om inte den registrerades intressen och grundläggande rättigheter väger tyngre (intresseavvägning).
Föreningen omfattas av flera av de ovan angivna rättsliga grunderna för behandling av person-uppgifter. Behandling av känsliga personuppgifter är som utgångspunkt inte tillåten enligt huvudregeln i dataskyddsförordningen/GDPR. Undantag från huvudregeln kan gälla bl.a. vid den registrerades utryckliga samtycke. Vidare kan behandling ske av känsliga uppgifter utifrån reglering i nationell lagstiftning och EU-rätt.</p>
          

          <h3>4 Skydd av personuppgifter, åtkomst, rättelse m.m.</h3>
          <p>Föreningen hanterar och förvarar registrerads personuppgifter på ett säkert sätt i enlighet med krav som ställs på verksamhetsutövare under Finansinspektionens och Integritetsskyddsmyndighetens tillsyn. Föreningens verksamhet är av begränsad omfattning vilket medför att administration och förvaring av personuppgifter underlättas.

Gallring av personuppgifter rörande de registrerade ska ske i enlighet med reglerna i penning-tvättslagstiftningen, betaltjänstlagstiftningen och relevanta dataskyddsbestämmelser.

De som är registrerade hos Föreningen har rätt att ta del av egna personuppgifter. Detta gäller dock inte utifrån de begränsningar som stadgas i penningtvättslagstiftningen. Den registrerade har också under vissa förutsättningar rätt att få personuppgifter raderade.

Föreningen ska således enligt huvudregeln på lämpligt sätt tillhandahålla personuppgifter som den registrerade tidigare lämnat till Föreningen för ingående av avtal och behandling m.m. Under vissa förutsättningar kan sådan information lämnas för förflyttning i ett maskinläsbart format (dataportabilitet), dock inte utifrån de begränsningar som stadgas i penningtvättslagstiftningen eller annan motsvarande lagstiftning.

Om registrerads uppgifter är ofullständiga eller felaktiga har den registrerade rätt att begära rättelse av Föreningen utifrån tillämplig lagstiftning eller genom utnyttjande av rätt till invändning avseende behandlingen av uppgifter utifrån tillämplig lagstiftning. Genom regelverket för åtgärder mot penningtvätt och finansiering av terrorism samt vissa delar av betaltjänstregelverket finns begränsningar för den registrerades åtkomst av de uppgifter som omfattas av sekretess.

Om behandling av registrerade personuppgifter behövs för att hävda ett rättsligt anspråk kan de registrerade yrka på att annan behandling av uppgifterna begränsas till lagring.

I enlighet med vissa regler ska Föreningen anmäla s.k. personuppgiftsincidenter om det skulle aktualiseras. Integritetsskyddsmyndigheten är mottagare av sådana anmälningar.  En personuppgiftsincident definieras som en incident som avser oavsiktlig eller avsiktlig förstörelse, förlust, ändring eller obehörig åtkomst till personuppgifter.

Föreningens policy för informationssäkerhet innefattar bl.a. att kunders personuppgifter förvaras och hanteras på ett säkert med användning av tekniska och personella lösningar och åtgärder för behörighetskontroll beträffande system som innehåller och hanterar personuppgifter samt att loggning sker vid all användning av Föreningens register.

</p>
         <h3>5 Kontaktinformation</h3>
         <p>Tel: 46736632349

Email: info@amalsweden.com</p>
         </div>
      </div>
		</>
    );
}

export default PrivacyPolicyContent;